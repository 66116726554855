// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.tsx");
  import.meta.hot.lastModified = "1731095780048.3142";
}
// REMIX HMR END

import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate, useNavigation, useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NS } from '~/ui/icons/NS';
import { Button } from '~/ui/atoms/Button';
import { Empty } from '~/ui/atoms/Empty';
import { Form } from '~/ui/atoms/Form';
import { Input } from '~/ui/atoms/Input';
import { Text } from '~/ui/atoms/Text';
import { Banner } from '~/ui/molecules/Banner';
import { loginSchema, useLogin, useLoginOAuth, useLoginWidget } from '~/client/hooks/login';
import { inIframe } from '~/root';
import { useEnv, useOptionalUser } from '~/utils';
export default function RouteLogin() {
  _s();
  const env = useEnv();
  const [params] = useSearchParams();
  const error = params.get('error');
  const expired = params.get('expired') === 'true';
  const loggedOut = params.get('loggedOut') === 'true';
  const noRedirect = params.get('noRedirect') === 'true' || expired || loggedOut;
  const nav = useNavigate();
  const user = useOptionalUser();

  // If we have a `widgetAccount` query param, we are in an iframe.
  // Only set this once.
  useEffect(() => {
    if (typeof inIframe.account === 'string') return;
    inIframe.account = params.get('widgetAccount');
  }, [params]);

  // Nav away from login if the user is logged in
  // If the user is logged in and we are in an iframe, we need to redirect to the
  // chats page in the widget account
  useEffect(() => {
    if (user && !noRedirect) {
      if (inIframe.account != null && params.get('widgetAccount')) {
        nav(`/${params.get('widgetAccount')}/chats`);
      } else {
        nav('/');
      }
    }
  }, [user, nav, noRedirect, params]);
  return <main className='flex min-h-screen items-center justify-center bg-gray-50 p-6'>
      <NS className='absolute left-5 top-5 h-10 w-auto' />
      {env?.AUTH_PROVIDER === 'okta' && <div>
          <AutoOAuthLogin />
          {error && <Empty variant='error' className='flex flex-col gap-3'>
              <Text asChild truncate={false}>
                <p>There was a problem logging in: {error}</p>
              </Text>
              <Text asChild truncate={false}>
                <p>
                  Check with your IT administrator to ensure that your account
                  is configured correctly.
                </p>
              </Text>
              <Button>
                <Link to='/'>Try again</Link>
              </Button>
            </Empty>}
        </div>}
      {env?.AUTH_PROVIDER === 'ns' && <div className='w-full max-w-md rounded border bg-white p-10'>
          <h1 className='mb-2 text-left text-2xl font-medium'>Welcome back</h1>
          <p className='mb-6 text-left text-sm'>
            Sign in to Numbers Station Analytics Copilot
          </p>
          <EmailAndPasswordForm />
          {error && <Banner variant='error' className='mt-4'>
              {error}
            </Banner>}
          {expired && <Banner variant='info' className='mt-4'>
              Session has expired. Please sign in again.
            </Banner>}
          {loggedOut && <Banner variant='success' className='mt-4'>
              Successfully logged out.
            </Banner>}
          <footer className='mt-6 flex'>
            {env?.SIGNUP === true && <p className='text-sm'>
                Don’t have an account?
                <Link className='ml-1 text-blue-500' to='/signup'>
                  Sign up
                </Link>
              </p>}
            <p className='ml-8 flex-auto text-right text-sm'>
              <Link className='text-blue-500' to='/forgot-password'>
                Forgot password?
              </Link>
            </p>
          </footer>
        </div>}
    </main>;
}
_s(RouteLogin, "iY+jsLt+H0eUDOLCU0FebAh+70s=", false, function () {
  return [useEnv, useSearchParams, useNavigate, useOptionalUser];
});
_c = RouteLogin;
function AutoOAuthLogin() {
  _s2();
  const {
    error
  } = useLoginOAuth();
  if (error) return <Banner.ErrorContent error={error} />;
  return <div className='text-secondary'>Redirecting...</div>;
}
_s2(AutoOAuthLogin, "RtWRl1zlQ3hXB3t7AObNgYdphgw=", false, function () {
  return [useLoginOAuth];
});
_c2 = AutoOAuthLogin;
function EmailAndPasswordForm() {
  _s3();
  const {
    mutate: login,
    error: errorLogin,
    isPending: isPendingLogin
  } = useLogin();
  const {
    mutate: loginWidget,
    error: errorWidget,
    isPending: isPendingWidget
  } = useLoginWidget({
    widgetAccount: inIframe.account
  });
  const form = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: '',
      password: ''
    }
  });
  const slackId = useSearchParams()[0].get('slackId');
  const error = errorLogin ?? errorWidget;
  const isPending = isPendingLogin || isPendingWidget;
  return <Form {...form}>
      <form onSubmit={form.handleSubmit(data => {
      if (inIframe.account != null) {
        loginWidget(data);
      } else {
        login({
          ...data,
          slackId
        });
      }
    })} className='flex flex-col gap-4'>
        <Form.Field control={form.control} name='username' render={({
        field
      }) => <Form.Item>
              <Form.Label>Email</Form.Label>
              <Form.Control>
                <Input {...field} size='lg' type='email' autoComplete='username' data-cy='login-page-username' value={field.value ?? undefined} />
              </Form.Control>
              <Form.Message />
            </Form.Item>} />
        <Form.Field control={form.control} name='password' render={({
        field
      }) => <Form.Item>
              <Form.Label>Password</Form.Label>
              <Form.Control>
                <Input {...field} size='lg' type='password' autoComplete='current-password' data-cy='login-page-password' value={field.value ?? undefined} />
              </Form.Control>
              <Form.Message />
            </Form.Item>} />
        <Button size='lg' type='submit' disabled={useNavigation().state !== 'idle' || isPending} data-cy='login-page-submit'>
          {isPending ? 'Signing in...' : 'Sign in'}
        </Button>
        {error && <Banner.ErrorContent error={error} />}
      </form>
    </Form>;
}
_s3(EmailAndPasswordForm, "L4ottPMUcrPE7NkfT2p6No2c3j8=", false, function () {
  return [useLogin, useLoginWidget, useForm, useSearchParams, useNavigation];
});
_c3 = EmailAndPasswordForm;
var _c, _c2, _c3;
$RefreshReg$(_c, "RouteLogin");
$RefreshReg$(_c2, "AutoOAuthLogin");
$RefreshReg$(_c3, "EmailAndPasswordForm");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;